import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { setETicket, setPastMyTickets, setUpcomingMyTickets } from 'src/app/actions/mytickets.actions';
import { MyTicketTypes } from 'src/app/enums/tickets/myTicket/myTicketTypes';
import { ApiRequestService } from '../ApiRequest/api-request.service';
import { TicketData, TicketPrinterService } from '../TicketPrinter/ticket-printer.service';
import { Ticket } from 'src/app/interfaces/State/MyTickets/ticket';
import { PurchaseType } from 'src/app/enums/stadium/purchase-type';

@Injectable({
  providedIn: 'root'
})
export class MyTicketService {

  constructor(
    private _apiRequestService: ApiRequestService,
    private _store: Store,
    private _printer: TicketPrinterService,
  ) { }

  public async getUpcomingTickets() {
    try {
      const res = await this._apiRequestService.getRequest('tickets/' + MyTicketTypes.UPCOMING)
      this._store.dispatch(setUpcomingMyTickets({tickets: res.data})) 
    } catch (e: any)
    {
      ;
    } 
  }

  public async getPastTickets() {
    const res = await this._apiRequestService.getRequest('tickets/' + MyTicketTypes.PAST)
    this._store.dispatch(setPastMyTickets({tickets: res.data}))   
}

  public async downloadTicket(fixtureSlug: string, seatId: number) {
    return await this._apiRequestService.getRequest('tickets/download/' + fixtureSlug + '/' + seatId)
  }

  public getAppleWalletPass(fixtureSlug: string, seatId: number) {
    this._apiRequestService.downloadFileRequest('tickets/apple-wallet-pass/' + fixtureSlug + '/' + seatId, [], 'application/vnd.apple.pkpass', {filename: 'pass', extension: 'pkpass'})
  }

  /**
   * Sends a Print request to the given printer with the given ticket data. 
   * @param ticket 
   * @param fixture title-like string for the ticket printed
   * @param printerAddress Printer HTTP address with port number
  */
  public async printTicket(ticket: Ticket, fixture: string, printerAddress: string) {
    this._printer.printerAddress = printerAddress;

    const ticketData: TicketData|undefined|null = ticket.printData;
    if(!ticketData) return;

    await this._printer.printTicket(ticketData, ticket.personal_details.array);
  }

  public async setPersonalDetails(fixtureId: string, ticketId: number, controls: any, purchaseType: PurchaseType = PurchaseType.TICKET) {
    try {
      let personalDetails : {[key:string]: string} = {}

      Object.keys(controls).map((key: string) => {
        if (controls[key].value != "" && typeof controls[key].value === 'object') {
          personalDetails[key] = controls[key].value?.id ??  controls[key].value
        } else {
          personalDetails[key] = controls[key].value
        }
        
      })
    
      let res 

      if(purchaseType == PurchaseType.TICKET) {
        res = await this._apiRequestService.putRequest('me/fixture/' + fixtureId + '/ticket/' + ticketId + '/personal-details', personalDetails);
      } 

      this._store.dispatch(setETicket({ticket: res.data}))

      return true;
    } catch (e: any) {
      return e.error
    }
  }
}
