import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultComponent } from './layouts/default/default.component';
import { CartComponent } from './pages/cart/cart.component';
import { IndexComponent as FixtureIndexComponent } from './pages/fixture/index/index.component';
import { IndexComponent as SeasonPassIndexComponent } from './pages/season-pass/index/index.component';
import { HomescreenComponent } from './pages/homescreen/homescreen.component';
import { MyTicketsComponent } from './pages/my-tickets/my-tickets.component';
import { OrderResultComponent } from './pages/order-result/order-result.component';
import { StadiumComponent } from './pages/stadium/stadium.component';
import { MySeasonPassesComponent } from './pages/my-season-passes/my-season-passes.component';
import { VerifyComponent } from './pages/auth/verify/verify.component';
import { ResetPasswordComponent } from './layouts/_partials/modals/login-register-modal/_partials/reset-password/reset-password.component';
import { OrderInvoiceComponent } from './pages/order-invoice/order-invoice.component';
import { GameComponent } from './pages/game/game.component';
import { ETicketComponent } from './pages/e-ticket/e-ticket.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { TermsComponent } from './pages/terms/terms.component';
import { CashierOrdersComponent } from './pages/cashier-orders/cashier-orders.component';
import { LocalizeParser, LocalizeRouterConfig, LocalizeRouterModule, LocalizeRouterSettings, ManualParserLoader } from '@gilsdav/ngx-translate-router';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import { LocalizeRouterHttpLoader } from '@gilsdav/ngx-translate-router-http-loader';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {Location} from "@angular/common";
import { environment } from 'src/environments/environment';
import { CONFIGURATION_MAP, Environment, Client } from 'src/configurations/configuration-map';
import { FlexComponent } from './pages/flex/flex.component';

const routes: Routes = [
  {
    path: '', component: DefaultComponent, children: [
      { path: '', component: HomescreenComponent },
      { path: 'fixtures', component: FixtureIndexComponent },
      { path: 'season-passes', component: SeasonPassIndexComponent },
      { path: 'fixture/:id', component: StadiumComponent},
      { path: 'season-pass/:id', component: StadiumComponent, data: { hasLocalizedSegment: true, localizedPath: 'season-pass/:id', localizedSegments : [{segment: ':id', data: 'seasonPasses.openedSeasonPass.urls'}]}},
      { path: 'cart', component: CartComponent },
      { path: 'order/:id', component: OrderResultComponent },
      { path: 'order/:id/invoice', component: OrderInvoiceComponent },
      { path: 'my-tickets', component: MyTicketsComponent },
      { path: 'my-tickets/fixtures/:merkozes/ticket/:jegy', component: ETicketComponent },
      { path: 'my-tickets/season-pass/:berlet/ticket/:jegy', component: ETicketComponent, data: { hasLocalizedSegment: true, localizedPath: 'my-tickets/season-pass/:berlet/ticket/:jegy', localizedSegments : [{segment: ':berlet', data: 'mySeasonPasses.eSeasonPass.season_pass.urls'}]}},
      { path: 'verify', component: VerifyComponent },
      { path: 'change-password', component: ResetPasswordComponent}, 
      { path: 'games/:slug', component: GameComponent},
      { path: 'terms', component: TermsComponent },
      { path: 'privacy', component: PrivacyComponent },
      { path: 'orders', component: CashierOrdersComponent },
      { path: 'flex', component: FlexComponent },
      { path: 'flex/:id', component: StadiumComponent },
    ]
  },
  {path: '**', redirectTo: ''},
];

export function HttpLoaderFactory(translate: TranslateService, location: Location, settings: LocalizeRouterSettings, http: HttpClient) {
  return new LocalizeRouterHttpLoader(translate, location, { ...settings }, http);
}

export function getClient(): Client
{
    return environment.environment == 'development' ? environment.client as Client : window.location.hostname.split('.')[0] as Client 
}

export function getDefaultLanguage(client: Client) : string
{
  const defaultLanguages = {
    szpari : 'hu',
    test: 'hu',
    default: 'en',
  }

  return defaultLanguages[client] || defaultLanguages.default
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      onSameUrlNavigation: "reload",
      anchorScrolling: "enabled",
    }),
    LocalizeRouterModule.forRoot(routes, {
      alwaysSetPrefix: false,
      useCachedLang: false,
      parser: {
        provide: LocalizeParser,
        useFactory: HttpLoaderFactory,
        deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
      },
       defaultLangFunction: (languages: string[], cachedLang?: string, browserLang?: string): string =>  {
        return getDefaultLanguage(getClient())
      }
    }),
    RouterModule.forChild(routes),
    LocalizeRouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
    LocalizeRouterModule,
  ],
  providers: [
   
  ],
})
export class AppRoutingModule {

 }
