export const environment = {
  production: false,
  environment: 'staging',
  apiUrl: 'https://api.stage.csakjegyek.hu/ticketing-api/',
  client: '',
  clients: {
    test: {
      frontendEncryptDecryptKey: 'qWRl0TnDvekCJtCLD0bSmtX/ho0lcJ1k1ESt0A+rgNE=',
      flex: true,
      enableMobileTicketDownload: true,
    },
    szpari: {
      frontendEncryptDecryptKey: 'qWRl0TnDvekCJtCLD0bSmtX/ho0lcJ1k1ESt0A+rgNE=',
      flex: true,
      enableMobileTicketDownload: false,
    },
    banyasz: {
      frontendEncryptDecryptKey: 'qWRl0TnDvekCJtCLD0bSmtX/ho0lcJ1k1ESt0A+rgNE=',
      flex: false,
      enableMobileTicketDownload: true,
    }
  }
};
